<template>
  <section class="why">
    <div class="container">
      <h2 class="why__title section-title">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.whyTitle")
        }}</h2>
      <p class="why__text">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.whyText")
        }}</p>
      <div class="why__content">
        <div class="why__arguments">
          <div class="why__picture">
            <img class="why__img" src="@/assets/images/About/3.jpg" alt="five">
          </div>
          <p class="why__arguments-text" lang="uk">{{
              $vuetify.lang.t("$vuetify.view.aboutUs.whyArgument")
            }}</p>
        </div>
        <ul class="why__list">
          <li class="why__item" v-for="(item, i) in whyList" :key="i">
            <div class="why__item-decor"></div>
            <p class="why__item-text">{{ item.description }}</p>
          </li>
        </ul>
      </div>
      <h2 class="why__title section-title mt-16">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.whySubtitle")
        }}</h2>
      <router-link to="/therapists">
        <button class="main-btn why__main-btn">{{
            $vuetify.lang.t("$vuetify.view.aboutUs.whyButton")
          }}</button>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhySection',
  computed: {
    whyList () {
      return [
        {
          description: this.$vuetify.lang.t('$vuetify.view.aboutUs.whyList.description1')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.aboutUs.whyList.description2')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.aboutUs.whyList.description3')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.aboutUs.whyList.description4')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.aboutUs.whyList.description5')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.why {
  padding: 50px 0;
  &__title {
    font-size: 1.7rem;
  }
  &__text {
    margin-top: 40px;
    font-weight: 400;
    line-height: normal;
    color: $darkTextColor;
  }
  &__content {
    margin-top: 60px;
    display: flex;
    gap: 20px;
  }
  &__arguments {
    display: flex;
    gap: 20px;
    width: 50%;
  }
  &__picture {
    padding-top: 10px;
    max-height: 330px;
    height: 100%;
    max-width: 210px;
    width: 100%;
  }
  &__img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
  &__arguments-text {
    max-width: 230px;
    color: $darkTextColor;
    font-size: 1.7rem;
    line-height: normal;
    hyphens: auto;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 50%;
    padding: 0;
    list-style: none;
  }
  &__item {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  &__item-decor {
    padding: 10px;
    width: 20px;
    height: 20px;
    background-color: $lightColor;
    transform: rotate(45deg);
  }
  &__item-text {
    color: $darkTextColor;
    margin-bottom: 0;
    font-weight: 400;
    line-height: normal;
  }
  &__main-btn {
    padding: 15px 35px;
    margin: 60px auto 0;
  }
}
@media only screen and (max-width: 769px) {
  .why {
    &__content {
      flex-direction: column;
      gap: 50px;
    }
    &__arguments {
      justify-content: center;
      width: 100%;
    }
    &__list {
      width: 100%;
    }
  }
}
</style>
