import { render, staticRenderFns } from "./ForSection.vue?vue&type=template&id=3e651057&scoped=true&"
import script from "./ForSection.vue?vue&type=script&lang=js&"
export * from "./ForSection.vue?vue&type=script&lang=js&"
import style0 from "./ForSection.vue?vue&type=style&index=0&id=3e651057&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e651057",
  null
  
)

export default component.exports