<template>
  <section class="for">
    <div class="container">
      <h2 class=" for__title section-title">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.forTitle")
        }}</h2>
      <ul class="for__list">
        <li class="for__item">
          <div class="for__picture">
            <img class="for__img" src="@/assets/images/About/2_1.jpg" alt="balance">
          </div>
          <p class="for__item-description" lang="uk">{{
              $vuetify.lang.t("$vuetify.view.aboutUs.forDescription1")
            }}</p>
        </li>
        <li class="for__item">
          <div class="for__picture">
            <img class="for__img" src="@/assets/images/About/2_2.jpg" alt="specialist">
          </div>
          <p class="for__item-description" lang="uk">{{
              $vuetify.lang.t("$vuetify.view.aboutUs.forDescription2")
            }}</p>
        </li>
      </ul>
      <p class="for__text" lang="uk">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.forText")
        }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style lang="scss" scoped>
.for {
  padding: 50px 0;
  background-color: $backgroundColor;
  &__title {
    font-size: 1.7rem;
  }
  &__list {
    margin-top: 50px;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    list-style: none;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 531px;
  }
  &__picture {
    border-radius: 8px;
    border: 3px solid #2fa3b5;
    overflow: hidden;
    width: 53%;
    height: 290px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__item-description {
    margin-top: 20px;
    color: $darkTextColor;
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
    text-align: justify;
    hyphens: auto;
  }
  &__text {
    margin-top: 40px;
    font-weight: 400;
    line-height: normal;
    color: $darkTextColor;
  }
}
@media only screen and (max-width: 1110px)  {
  .for {
    &__list {
      justify-content: center;
    }
    &__picture {
      height: auto;
    }
  }
}
</style>
