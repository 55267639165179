<template>
  <div>
    <hero-section></hero-section>
    <for-section></for-section>
    <why-section></why-section>
  </div>
</template>

<script>
import HeroSection from '@/components/main/About/HeroSection'
import ForSection from '@/components/main/About/ForSection'
import WhySection from '@/components/main/About/WhySection'
export default {
  components: {
    HeroSection,
    ForSection,
    WhySection
  },
  name: 'About',
  metaInfo () {
    return {
      title: 'Сервіс підбору психологів',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Платформа – сервіс професійної психологічної допомоги \n' +
            'перевіреними експертами з досвідом'
        },
        {
          name: 'keywords',
          content: 'Підібрати психолога, досвідчений психолог, професійний \n' +
            'психолог, психотерапевт'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
