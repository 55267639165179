<template>
  <section class="hero">
    <div class="container">
      <h1 class="hero__title">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.heroTitle")
        }}</h1>
      <h3 class="hero__subtitle">{{
          $vuetify.lang.t("$vuetify.view.aboutUs.heroSubtitle")
        }}</h3>
      <div class="hero__picture">
        <img class="hero__img" src="@/assets/images/About/hero-picture.jpg" alt="hero-picture">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style lang="scss" scoped>
.hero {
  padding: 80px 0 20px;
  &__title {
    margin: 0 auto;
    max-width: 660px;
    font-size: 2rem;
    font-weight: 500;
    color: $darkGrey;
    text-align: center;
  }
  &__subtitle {
    margin: 20px auto 0;
    max-width: 600px;
    color: $darkTextColor;
    font-size: 1.3rem;
    font-weight: 300;
    text-align: center;
    line-height: normal;
  }
  &__picture {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  &__img {
    width: 90%;
    object-fit: contain;
  }
}
</style>
